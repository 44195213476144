import styled from 'styled-components'
import { ButtonProps, ContainerProps } from './types'
import { TiArrowBack } from 'react-icons/ti'
import { HiOutlineInbox } from 'react-icons/hi'

export const Container = styled.li<ContainerProps>`
  width: 100%;
  display: flex;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #D6DBE6;
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ selected }) => selected ? '#52BF4D' : 'transparent'};
  transition: 300ms;
  cursor: pointer;

  &:hover {
    border: 1px solid #52BF4D;
  }
`

export const Title = styled.h3<ContainerProps>`
  color: ${({ selected }) => selected ? '#FBFBFB' : '#5F5D7E'};
  font-size: 16px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 12px;
`

export const Button = styled.a.attrs((props: ButtonProps) => {})`
  padding: 8px 13px;
  height: 30px;
  background-color: #ECEDF3;
  color: #5F5D7E;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin: ${({ margin }) => margin ?? 0};
  border-radius: 6px;
  text-decoration: none;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 60%;
  max-width: 60%;
`

export const BoxQuestionQuantity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const QuestionTitle = styled.h5<ContainerProps>`
  color: ${({ selected }) => selected ? '#FBFBFB' : '#52BF4D'};
  font-size: 10px;
`

export const Quantity = styled.span<ContainerProps>`
  color: ${({ answer }) => answer ? '#52BF4D' : '#BDBDC9'};
  font-size: 22px;
  margin-top: 5px;
  color: ${({ notAnsweredQuestionAmount }) => notAnsweredQuestionAmount && 'red' };
  color: ${({ selected }) => selected && '#FBFBFB'};
`

export const NotAnswerTitle = styled.h5<ContainerProps>`
  color: ${({ notAnsweredQuestionAmount }) => notAnsweredQuestionAmount ? 'red' : '#BDBDC9' };
  color: ${({ selected }) => selected && '#FBFBFB'};
  font-size: 10px;
`

export const AnswerIcon = styled(TiArrowBack)<ContainerProps>`
  width: 18px;
  height: unset;
  margin-bottom: 2px;
  color: ${({ selected }) => selected ? '#FBFBFB' : '#52BF4D'};
`

export const NotAnswerIcon = styled(HiOutlineInbox)<ContainerProps>`
  width: 18px;
  height: unset;
  margin-bottom: 2px;
  color: ${({ notAnsweredQuestionAmount }) => notAnsweredQuestionAmount ? 'red' : '#BDBDC9' };
  color: ${({ selected }) => selected && '#FBFBFB'};
`
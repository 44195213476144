import { useEffect, useRef, useState } from "react";
import useStorage from "../../hooks/useStorage";
import { Logo } from "../shared";
import * as Styled from "./styles";
import { BoxDropdown, BoxDropdownItem } from "../shared/BoxDropdown/styles";

const Header = () => {
  const [, , removeStore] = useStorage("store");
  const [user, , removeUser] = useStorage("user");
  const [, , removeToken] = useStorage("token");
  const [dropDownOpen, setDropDownOpen] = useState<Boolean>(false);
  const dropDownRef = useRef(null);

  const acronym = user?.firstName && `${user?.firstName[0]}${user?.firstName[1]}`.toUpperCase();

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  // useEffect(() => {
  //   store?.store &&
  //     api.get(`/view/quantity/${store?.store}`).then(({ data }) => {
  //       setView(data.view);
  //     });
  // }, [store?.store]);

  useOutsideAlerter(dropDownRef);

  const handleClickConfig = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleClickDropdownItem = () => {
    setDropDownOpen(false);
  };

  const handleClickLogout = () => {
    setDropDownOpen(false);
    removeUser();
    removeToken();
    removeStore();
  };

  return (
    <Styled.Header>
      <Logo color="#52BF4D" width="80px" pathname="/"></Logo>
      <Styled.NavCenter>
        <Styled.NavItem to="emailconfig" onClick={handleClickDropdownItem}>
          Envio de e-mail
        </Styled.NavItem>
        {/* <Styled.NavItem to="analyst" onClick={handleClickDropdownItem}>
          Analista
        </Styled.NavItem> */}
        <Styled.NavItem to="layoutConfig" onClick={handleClickDropdownItem}>
          Layout
        </Styled.NavItem>
      </Styled.NavCenter>
      <Styled.RightBox>
        {/* <Styled.IconButton>
          <Styled.IconBell />
        </Styled.IconButton> */}
        <Styled.BoxConfig>
          <Styled.IconButton onClick={handleClickConfig}>
            <Styled.IconGear />
          </Styled.IconButton>
          {dropDownOpen && (
            <BoxDropdown top={40} right={0} ref={dropDownRef}>
              <BoxDropdownItem to="/" onClick={handleClickLogout}>
                Logout
              </BoxDropdownItem>
            </BoxDropdown>
          )}
        </Styled.BoxConfig>
        <Styled.UserWrapper>
          <Styled.UserName>{user?.firstName}</Styled.UserName>
          <Styled.UserIcon>{acronym}</Styled.UserIcon>
        </Styled.UserWrapper>
      </Styled.RightBox>
    </Styled.Header>
  );
};

export default Header;

import { createContext, ReactNode, useEffect, useState } from "react";
import useQuestionList from "../../hooks/useQuestionList";
import api from "../../Services/api";

const HomeContext = createContext({});

export const HomeProvider = ({ children }: { children: ReactNode }) => {
  const [productList, setProductList] = useState<any>([]);
  const [questionFilter] = useState("byNoAnswer");
  const [allQuestions, setAllQuestions] = useState([]);
  const [questionList] = useQuestionList(allQuestions, questionFilter);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedQuestion, setSelectedQuestion] = useState<any>({});
  const [selectedProductName, setSelectedProductName] = useState("");
  const [warn, setWarn] = useState<String>('')

  useEffect(() => {
    const storeStorage = localStorage.getItem('store') + ''
    const formatedStore = JSON.parse(storeStorage)

    api
      .get(`/product/list/${formatedStore?.store}`)
      .then((data) => {
        setProductList(data.data.productList);
        setAllQuestions(data.data.productList[0]?.questions);
        setSelectedProduct(data.data.productList[0]);
        setSelectedProductName(data.data?.productList[0]?.productName);
      })
      .catch((error) => {
        setWarn('Você não tem permissão para ver os produtos')

        setTimeout(() => setWarn(''), 7000)
      });
  }, []);

  useEffect(() => {
    setAllQuestions(selectedProduct?.questions);
  }, [selectedProduct]);

  return (
    <HomeContext.Provider
      value={{
        productList,
        setProductList,
        questionList,
        setSelectedProduct,
        selectedProduct,
        setQuestionList: setAllQuestions,
        setSelectedQuestion,
        selectedQuestion,
        selectedProductName,
        setSelectedProductName,
        setWarnList: setWarn,
        warnList: warn
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomeContext;

import React, { useContext } from "react";
import HomeContext from "../../context/home-context";
import api from "../../Services/api";
import DeleteButton from "../DeleteButton";
import { ProductCardProps } from "../ProductCard/types";
import * as Styled from "./styles";
import { QuestionCardProps, QuestionObjectProps } from "./types";

const QuestionCard = (props: QuestionCardProps) => {
  const {
    question,
    answered,
    questionObject,
    index,
    setQuestionList,
    questionList,
    _id,
    productList,
    setProductList,
  } = props;
  const { setSelectedQuestion } = useContext<any>(HomeContext);

  const handleClickViewQuestion = () => {
    setSelectedQuestion(questionObject);
  };

  const handleOnDelete = () => {
    const newQuestionList = questionList?.filter(
      (item: QuestionObjectProps) => item._id !== _id
    );

    const newProductList = productList?.map((item: ProductCardProps) => {
      const itsThisProduct = item?.questions?.some(item => item._id === _id)

      if (itsThisProduct) return {
        ...item, 
        questions: item?.questions?.filter(item => item._id !== _id)
      }

      return item
    })

    setProductList && setProductList(newProductList) 
    setQuestionList && setQuestionList(newQuestionList);

    api.delete(`/question/delete/${_id}`)
  };

  return (
    <Styled.Container onClick={handleClickViewQuestion} index={index}>
      <DeleteButton onDelete={handleOnDelete}></DeleteButton>
      <Styled.Box>
        <Styled.Title>{question}</Styled.Title>
        <Styled.ButtonWrapper>
          {/* <Styled.Button margin="0 15px 0 0" onClick={handleClickViewQuestion}>Ver pergunta</Styled.Button>
          {answered && <Styled.Button margin="0 15px 0 0" onClick={handleClickViewQuestion}>Editar resposta</Styled.Button>} */}
          <Styled.Status
            border={!!questionObject?.userEmail ? "#52bf4d" : "#EB5757"}
            answered={!!questionObject?.userEmail}
          >
            {questionObject?.userEmail
              ? "E-mail do usuário preenchido"
              : "E-mail do usuário NÃO preenchido"}
          </Styled.Status>
        </Styled.ButtonWrapper>
      </Styled.Box>
      <Styled.Status answered={answered}>{`Status: ${
        answered ? "Respondido" : "Pendente"
      }`}</Styled.Status>
    </Styled.Container>
  );
};

export default QuestionCard;

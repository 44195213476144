import styled from 'styled-components'
import { BaseInputProps } from '../../components/shared/Input/types'

interface WrapperRowProps{
  width?: string
}

export const Container = styled.form`
  width: 95%;
  max-height: 88vh;
  max-width: 1366px;
  overflow: auto;
  padding: 25px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  border: 1px solid #d6dbe6;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 24px;
  align-items: flex-start;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`

export const TextArea = styled.textarea`
  width: 100%;
  height: 400px;
  background-color: #ffffff;
  border: 1px solid #d6dbe6;
  border-radius: 8px;
  padding: 15px;
  resize: none;
  outline: none;
`

export const InputText = styled.input.attrs((props: BaseInputProps) => {})`
  color: inherit;
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 0;
  padding-left: 1em;
  outline: none;
  border: none;
  height: 40px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #d6dbe6;
  &::placeholder {
    color: inherit;
    opacity: 0.6;
  }
`
export const PreviewHTML = styled.div`
  width: 100%;
  margin-top: 20px;
`

export const EmailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #d6dbe6;
  border-radius: 8px;
  padding: 10px;
`

export const WrapperRow = styled.div<WrapperRowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => width && width};
`

export const ResizeButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 30px;
  margin: 0 10px;
  cursor: pointer;
  color: #52bf4d;
`
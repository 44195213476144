import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color:#ffffff;
  padding: 50px;
  z-index: 3;
`

export const ProductList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  margin-top: 20px;
  max-height: 79vh;
  overflow: auto;
`

export const Close = styled.span`
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  color: #BDBDC9;
  cursor: pointer;
`
import { useEffect, useState } from "react"

const useQuestionList = (array, filter) => {
  const [ questionList, setQuestionList ] = useState([])

  useEffect(() => { 
    switch (filter){
      case 'byAnswer':
        return setQuestionList(array?.sort((a) => a.answered ? -1 : 1))
      case 'byNoAnswer':
        return setQuestionList(array?.sort((a) => a.answered ? 1 : -1))
      default:
        return setQuestionList(array)
    }
  }, [array, filter])

  return [ questionList ]
}

export default useQuestionList
import styled from "styled-components";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export const Box = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const DropDownTitle = styled.span`
  color: rgb(95, 93, 126);
  font-weight: 700;
  cursor: pointer;
  user-select: none;
`;

export const Calendar = styled(ReactCalendar)`
  border-radius: 8px;
  min-width: 350px;
  overflow: hidden;
  & .react-calendar__month-view__days {
    padding: 5px;
  }
  & .react-calendar__tile {
    border-radius: 4px;
    transition: 150ms;
    &:hover {
      background-color: #52bf4d;
      color: #ffffff;
    }
    &--now {
      border: 1px solid #52bf4d;
      background-color: transparent;
    }

    &--hoverStart,
    &--hoverEnd,
    &--active {
      background-color: #52bf4d;
      &:focus {
        background-color: #52bf4d;
      }
    }

    &--range {
      background-color: #cccccc4d;
      border-radius: 0;
      color: rgb(95, 93, 126);
    }

    &--rangeEnd {
      color: #ffffff;
      background-color: #52bf4d;
      border-radius: 0 15px 15px 0;
    }

    &--rangeStart {
      color: #ffffff;
      background-color: #52bf4d;
      border-radius: 15px 0 0 15px;
    }
  }
  & * {
    font-family: "Montserrat", sans-serif;
  }
  & .react-calendar__month-view__weekdays__weekday {
    & abbr {
      color: rgb(95, 93, 126);
      text-decoration: none;
    }
  }
`;

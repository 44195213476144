import React from 'react'
import * as Styled from './styles'

interface LabelProps {
  children: string,
  margin?: string,
  labelFor?: string
  color?: string
  fontSize?: string
}

const Label: React.FC<LabelProps> = ({ labelFor, children, margin, color, fontSize }) => {
  return (
  <Styled.Label fontSize={fontSize} color={color} for={labelFor} margin={margin} >{children}</Styled.Label>
  )
}

export default Label;
import { Link } from "react-router-dom";
import EaskLogo from "../../../assets/svgs/easkLogo";

interface LogoSVGProps {
  color?: String;
  pathname: string
  width?: String
}

const Logo = ({ color, pathname, width }: LogoSVGProps) => {
  return (
    <Link to={{ pathname: pathname }} style={{maxWidth: '300px'}}>
      <EaskLogo color={color} width={width} ></EaskLogo>
    </Link>
  );
};

export default Logo;

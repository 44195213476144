import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, CheckBox } from "../../components/shared";
import useStorage from "../../hooks/useStorage";
import api from "../../Services/api";
import useScript from "react-script-hook";
import * as S from "./styles";
declare const eAsk: any;

const LayoutConfig = () => {
  const [layout, setLayout] = useState<string>("");
  const [ styles ] = useState<object>({})
  const [store] = useStorage("store");
  const [ easkLoading ] = useScript({ src: "https://www.agenciaeplus.io/eask/index.js" });
  const history = useHistory();

  const handleSelect = (selectedLayout: string) => {
    setLayout(selectedLayout);
  };

  useEffect(() => {
    console.log(styles)
  }, [styles])

  useEffect(() => {
    !easkLoading &&
      eAsk({
        storeToken: "VVU8F1WF6Z9FIYCUTIPP1CYNJ4Z1NONSZQ5S1NYH",
        storeName: "Sua Loja",
        container: ".e-ask",
        containerClassList: [],
        productName: "Ambinente de teste",
        description: "",
        successMessage: "Pergunta enviada. Responderemos o mais breve possível",
        failedMessage: "Por favor, digite sua pergunta!",
        productId: 10,
        platform: "vtex",
        layout: layout,
        primaryColor: "#444444",
      });
  }, [easkLoading, layout]);

  useEffect(() => {
    const $ask = document.querySelector('.e-ask')
    $ask && ($ask.innerHTML = '')

    !easkLoading && eAsk({
      storeToken: "VVU8F1WF6Z9FIYCUTIPP1CYNJ4Z1NONSZQ5S1NYH",
      storeName: "Sua Loja",
      container: ".e-ask",
      productName: "Ambinente de teste",
      description: "",
      containerClassList: [],
      successMessage: "Pergunta enviada. Responderemos o mais breve possível",
      failedMessage: "Por favor, digite sua pergunta!",
      productId: 10,
      platform: "vtex",
      layout: layout,
      primaryColor: "#444444",
    });
  }, [easkLoading, layout])

  const handleSave = () => {
    console.log(`styles`, styles)
    api
      .put(`/setting/updateLayout/${store?.store}`, { dialogueLayout: layout, styles })
      .then(({ data }) => {
        history.push("/");
      });
  };

  useEffect(() => {
    api.get(`/setting/read/${store?.store}`).then(({ data }) => {
      setLayout(data?.setting?.dialogueLayout);
      console.log(data)
    });
  }, [store?.store]);

  return (
    <S.Container>
      <CheckBox
        margin="10px 0"
        externalState
        stateToggle={() => handleSelect("chat")}
        label="Chat"
        selected={layout === "chat"}
      />
      <CheckBox
        margin="10px 0"
        externalState
        stateToggle={() => handleSelect("simple")}
        label="Simples"
        selected={layout === "simple"}
      />
      {/* <StylesConfig setStyles={setStyles} styles={styles} /> */}
      <Button onClick={handleSave} margin="10px 0 0 0" width="fit-content">
        Salvar
      </Button>
      <S.BoxEask>
        <link href="https://www.agenciaeplus.io/eask/style.css" rel="stylesheet" />
        <div className="e-ask" style={{width: '100%'}}></div>
      </S.BoxEask>
    </S.Container>
  );
};

export default LayoutConfig;

import styled from 'styled-components'
import { BiTrash } from 'react-icons/bi'

export const DeleteButton = styled.button`
  width: fit-content;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 3px;
  background-color: transparent;
  border: none;
  font-size: 11px;
  border-radius: 5px;
  color: #EB5757;
  font-weight: 700;
`

export const DeleteIcon = styled(BiTrash)`
  width: 15px;
  height: 15px;
  color: #EB5757;
`

import styled from "styled-components";
import { BaseTextAreaProps } from "./types";
import { MdKeyboardBackspace } from "react-icons/md";
import { GrEmoji } from "react-icons/gr";

export const Container = styled.form`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 52px;
`;

export const Title = styled.h3`
  color: #5f5d7e;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const LabelQuestion = styled.span`
  color: #ff8000;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const QuestionText = styled.p`
  color: #5f5d7e;
  font-size: 16px;
  margin-bottom: 20px;
  word-break: break-all;
  white-space: pre-wrap;

  & b {
    font-weight: bold;
  }
`;

export const LavelAnswer = styled.span`
  color: #52bf4d;
  font-size: 18px;
  margin: 14px 0;
  margin-bottom: 20px;
`;

export const AnswerTextarea = styled.textarea.attrs(
  (props: BaseTextAreaProps) => props
)`
  width: 100%;
  height: 106px;
  border: 1px solid #d6dbe6;
  border-radius: 8px;
  resize: none;
  padding: 8px 14px;
`;

export const ButtonAnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const BoxShowSite = styled.div`
  display: flex;
`;

export const BoxPaginate = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const BoxPageNumber = styled.div`
  display: flex;
  align-items: center;
  margin: 0 30px;
`;

export const PageNumber = styled.span`
  color: #bdbdc9;
  font-size: 20px;
  margin: 0 5px;
`;

export const PageNumberSeparator = styled.span`
  color: #bdbdc9;
  font-size: 20px;
`;

export const BackIcon = styled(MdKeyboardBackspace)`
  width: 20px;
  height: unset;
  color: #bdbdc9;
  position: absolute;
  left: 17px;
  top: 20px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
`;

export const Emoji = styled(GrEmoji)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const PickerBox = styled.div`
  position: absolute;
  top: 130px;
  right: 0px;
  z-index: 2;
  height: fit-content;
  background-color: #ffffff;
`;

export const AnsweredBy = styled.p`
  margin-bottom: 20px;
  font-size: 12px;
  color: #5f5d7e;
`;

export const Date = styled.span`
  color: #5f5d7e;
  font-size: 12px;
  position: absolute;
  left: 10px;
  bottom: 10px;
`;

export const Warn = styled.span`
  color: red;
  font-size: 11px;
  margin-bottom: 20px;

  & b {
    font-weight: bold;
  }
`;

import { useCallback, useEffect, useState } from "react"

const useSliceProduct = (productList: Array<any>, length: number): Array<any> => {
  const [ slicedProduct, setSliceProduct ] = useState<Array<any>>([])

  const sliceProduct = useCallback((lastSliceList: Array<any>, length: number) => {
    const _newProductList = productList.reduce((acc, item, index) => {
      if (index < lastSliceList.length + length) return [...acc, item]
      return acc
    }, [])


    setSliceProduct(_newProductList)
  }, [productList])

  useEffect(() => {
    sliceProduct(slicedProduct, length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, productList, sliceProduct])

  return [slicedProduct, sliceProduct]
} 

export default useSliceProduct


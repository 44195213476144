import styled from 'styled-components'
import { BiTrash } from 'react-icons/bi'

export const Container = styled.li`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D6DBE6;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
`

export const BoxImage = styled.picture`
  display: flex;
  width: 40px;
  height: fit-content;
  min-height: 40px;
  justify-content: center;
  align-items: center;
`

export const Image = styled.img`
  width: 40px;
  height: auto;
`

export const ProductName = styled.h4`
  color: #5F5D7E;
  font-size: 18px;
  width: 100%;
  padding-left: 20px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Price = styled.strong`
  color: #5F5D7E;
  font-weight: 700;
`

export const ListPrice = styled.span`
  color: #5F5D7E;
  text-decoration: line-through;
  margin-bottom: 15px;
`

export const RemoveButton = styled(BiTrash)`
  width: 20px;
  height: auto;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #5f5d7e;
`
import styled from 'styled-components'
import { BoxDropdownprops } from './types'
import { Link } from 'react-router-dom'

export const BoxDropdown = styled.nav<BoxDropdownprops>`
  padding: 30px 20px 10px 20px;
  box-sizing: border-box;
  border: 1px solid #D6DBE6;
  border-radius: 8px;
  position: absolute;
  top: ${({ top }) => top && `${top}px`};
  left: ${({ left }) => left && `${left}px`};
  right: ${({ right }) => right && `${right}px`};
  z-index: 3;
  background-color: #ffffff;
  box-shadow: 3px 3px 3px #cccccc;
  display: flex;
  flex-direction: column;
`

export const BoxDropdownItem = styled(Link)`
  color: #5F5D7E;
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: none;
  white-space: nowrap;
`
import React, { useEffect, useRef, useState } from "react";
import { Button, Label } from "../../components/shared";
import api from "../../Services/api";
import * as Styled from "./styles";
import { useHistory } from "react-router-dom";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/webpack-resolver";
import FormatEmailHTML from "../../helpers/FormatEmailHTML";
import useStorage from "../../hooks/useStorage";

const EmailConfig = () => {
  const [defaultEmail, setDefaultEmail] = useState<string>("");
  const [htmlEditor, setHtmlEditor] = useState<string>("");
  const [ editorFontSize, setEditorFontSize ] = useState<number>(20)
  const email = useRef<any>(null);
  const history = useHistory();
  const [store] = useStorage("store");

  useEffect(() => {
    api.get(`/setting/read/${store?.store}`).then(({ data: { setting } }) => {
      setHtmlEditor(setting?.emailHTML);
      setDefaultEmail(setting?.principalEmail);
    });
  }, [store?.store]);

  const handleIncreaseFont = () => {
    setEditorFontSize(editorFontSize + 2)
  }
  const handleDecreaseFont = () => {
    editorFontSize >= 6 && setEditorFontSize(editorFontSize - 2)
  }

  const hendleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newEmail = email?.current.value;

    api
      .put(`/setting/update/${store?.store}`, {
        principalEmail: newEmail,
        emailHTML: htmlEditor,
      })
      .then(() => {
        history.push("/");
      });
  };

  return (
    <Styled.Container onSubmit={hendleSubmit}>
      <Styled.Wrapper>
        <Label labelFor="email-input" margin="0 0 10px 0">
          E-mail para avisos
        </Label>
        <Styled.InputText id="email-input" defaultValue={defaultEmail} ref={email} />
      </Styled.Wrapper>
      <Styled.WrapperRow width='100%'>
        <Label labelFor="html-field" margin="0 0 10px 0">
        E-mail enviado ao cliente
        </Label>
        <Styled.WrapperRow>
          <Styled.ResizeButton type="button" onClick={handleDecreaseFont}>-</Styled.ResizeButton>
          <Styled.ResizeButton type="button" onClick={handleIncreaseFont}>+</Styled.ResizeButton>
        </Styled.WrapperRow>
      </Styled.WrapperRow>
      <Styled.EmailWrapper>
        <AceEditor
          placeholder="HTML"
          mode="html"
          theme="textmate"
          name="html-field"
          onLoad={console.log}
          onChange={setHtmlEditor}
          fontSize={editorFontSize}
          showPrintMargin
          showGutter
          highlightActiveLine
          value={htmlEditor}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 4,
          }}
          style={{ width: "100%", padding: '20px' }}
        />
        <Styled.PreviewHTML dangerouslySetInnerHTML={{ __html: htmlEditor && FormatEmailHTML(htmlEditor) }} />
      </Styled.EmailWrapper>
      <Button width="fit-content" margin="20px 0 0 0">
        Salvar
      </Button>
    </Styled.Container>
  );
};

export default EmailConfig;

import { BrowserRouter, Switch } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoutes />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

import styled from 'styled-components'

export const BoxChart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
  background-color: #ffffff;
  border: 1px solid #d6dbe6;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 40px;
`

export const Title = styled.h3`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 900;
`
interface LogoSVGProps {
  color?: String
  width?: String
}

const EaskLogo: React.FC<LogoSVGProps> = ({ width, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 910.45 684.83"
      width={width ? width + ''  : 100}
      color={color && color + ''}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#4db848}.prefix__cls-2{fill:#fff}.prefix__cls-3{opacity:.2}.prefix__cls-4{fill:#333}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <g id="prefix__LOGO_03" data-name="LOGO 03">
            <path
              className="prefix__cls-1"
              d="M523.48 0c161.24 0 291.94 130.71 291.94 291.94a290.54 290.54 0 01-49.2 162.22l7.65 71.12A17.13 17.13 0 01755 544.15l-72.09-7.65a290.55 290.55 0 01-159.46 47.38c-161.23 0-291.94-130.7-291.94-291.94S362.25 0 523.48 0z"
            />
            <path
              className="prefix__cls-2"
              d="M451.22 255.57h28v118H451.7l-1.44-17.23c-6.7 13.88-25.13 20.58-38.29 20.82-34.93.24-60.78-21.29-60.78-62.69 0-40.68 27-62 61.5-61.74 15.8 0 30.87 7.42 37.57 19.14zm-70.83 58.87c0 22.49 15.55 35.89 34.93 35.89 45.95 0 45.95-71.55 0-71.55-19.38 0-34.93 13.16-34.93 35.66zM580.91 286.92c-8.37-7.9-17.95-10.53-29.19-10.53-13.88 0-21.54 4.3-21.54 11.72 0 7.66 6.94 12 22 12.92 22.26 1.44 50.5 6.47 50.5 37.81 0 20.82-17 38.77-50.74 38.77-18.66 0-37.33-3.11-54.56-21.06l14.36-20.82c8.38 9.34 27.52 16.28 40.68 16.52 11 .23 21.3-5.51 21.3-14.12 0-8.14-6.7-11.49-23.45-12.45-22.26-1.67-48.82-9.81-48.82-36.61 0-27.28 28.24-36.85 49.78-36.85 18.42 0 32.3 3.59 45.94 15.55zM649.82 206.27V303l39.73-47.38h34.93v1.67l-48.09 53.85 54.8 60.3v2.15H696l-46.19-53.36v53.36h-29.18V206.27z"
            />
            <g className="prefix__cls-3">
              <path
                className="prefix__cls-4"
                d="M258.94 170.75c-2.59 5.2-5.05 10.49-7.33 15.91a273.59 273.59 0 0023.64 257.12 166.53 166.53 0 00-16.31-273z"
              />
            </g>
            <path
              className="prefix__cls-4"
              d="M320.42 312.46A160.22 160.22 0 0171.38 446l-39 4.25A9.4 9.4 0 0122 440l4.14-39.57a160.21 160.21 0 11294.29-87.94z"
            />
            <path
              className="prefix__cls-2"
              d="M82.14 323.82c2 15.24 15.24 26.23 36.73 26.23 11.24 0 26-4.25 33-11.49l19.49 19.24c-13 13.49-34.23 20-53 20-42.48 0-67.72-26.24-67.72-65.72 0-37.48 25.49-64.46 65.47-64.46 41.23 0 67 25.48 62.21 76.21zm67-25.24c-2-16-14.49-24-32-24-16.49 0-30 8-34.48 24zM200.56 298.7h69.2v27.98h-69.2z"
            />
            <g className="prefix__cls-3">
              <ellipse
                className="prefix__cls-4"
                cx={397.28}
                cy={656.21}
                rx={380.29}
                ry={28.62}
              />
            </g>
            <path
              className="prefix__cls-1"
              d="M777.64 96.35a14.72 14.72 0 01-14.72-14.72V17.05a14.72 14.72 0 0129.44 0v64.58a14.72 14.72 0 01-14.72 14.72zM817.84 121.3a14.72 14.72 0 01-10.41-25.12l45.67-45.67a14.72 14.72 0 0120.81 20.82L828.25 117a14.64 14.64 0 01-10.41 4.3zM895.73 168.67h-64.58a14.72 14.72 0 010-29.44h64.58a14.72 14.72 0 110 29.44z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default EaskLogo

import { useContext } from "react";
import HomeContext from "../../context/home-context";
import * as S from "./styles";

const ModalWarn = () => {
  const { warnList } = useContext<any>(HomeContext);

  return warnList ? (
    <S.Container>
      <S.WarnCard>{warnList}</S.WarnCard>
    </S.Container>
  ) : (
    <></>
  );
};

export default ModalWarn;

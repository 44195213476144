import React, { useState } from 'react'
import * as Styled from './styles'
import { CheckBoxProps } from './types'
import { MdDone } from 'react-icons/md'

const CheckBox: React.FC<CheckBoxProps> = ({ label, stateToggle, selected, externalState, margin }) => {
  const [ checked, setChecked ] = useState(selected)

  const handleClickCheckbox = () => {
    !externalState && setChecked(!checked)
    stateToggle && stateToggle(checked)
  }

  return(
    <Styled.Box margin={margin}>
      <Styled.CheckBox onClick={handleClickCheckbox}>
        {(externalState ? selected : checked) && <MdDone size={20} color='#52BF4D'/>}
      </Styled.CheckBox >
      <Styled.Label onClick={handleClickCheckbox}>{label}</Styled.Label>
    </Styled.Box>
  )
}

export default CheckBox
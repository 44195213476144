import styled from 'styled-components'
import { BiSearchAlt2 } from 'react-icons/bi'

export const Container = styled.form`
  display: flex;
  width: 100%;
  height: 39px;
`

export const BoxInput = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #D6DBE6;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;

  &:focus-within{
    border: 1px solid #0000004d;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
`

export const SearchIcon = styled(BiSearchAlt2)`
  width: 20px;
  height: 20px;
  margin: 10px 14px;
  color: #BDBDC9;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 100%;
  color: #ffffff;
  border-radius: 8px;
  margin-left: 16px;
  background-color: #52BF4D;
  border: none;
  font-size: 16px;
`
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://easkapi.agenciaeplus.io',
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token')?.replace(/"/g, '')

  token && (config.headers.Authorization = `Bearer ${token}`)

  return config
})

export default api;
import { useContext } from "react";
import { Route } from "react-router-dom";
import { Header } from "../../components";
import ModalWarn from "../../components/ModalWarnList";
import SearchProduct from "../../components/SearchProduct";
import GlobalContext from "../../context/global-context";
import { HomeProvider } from "../../context/home-context";
import Analyst from "../../pages/Analyst";
import Auth from "../../pages/Auth";
import EmailConfig from "../../pages/EmailConfig";
import Home from "../../pages/home";
import LayoutConfig from "../../pages/LayoutConfig";
import * as Styled from "./styles";

const PrivateRoutes = () => {
  const { productToSearch } = useContext<any>(GlobalContext)

  return (
    <Styled.Container>
      <HomeProvider>
        <Header></Header>
        <Route exact path="/" component={Home}></Route>
        <ModalWarn></ModalWarn>
        <Route path="/emailconfig" component={EmailConfig}></Route>
        <Route path="/analyst" component={Analyst}></Route>
        <Route path="/layoutConfig" component={LayoutConfig}></Route>
        <Route path="/auth/:token" component={Auth} />
      </HomeProvider>
      { productToSearch && <SearchProduct/> }
    </Styled.Container>
  );
};

export default PrivateRoutes;

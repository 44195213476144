import React from "react";
import * as Styled from "./styles";
import { ButtonProps } from "./types";

const Button: React.FC<ButtonProps> = ({ children, color, outlined, width, margin, onClick, type, loading, ...props }) => {
  return (
    <Styled.Button {...props} type={type} loading={loading} onClick={onClick} color={color} outlined={outlined} width={width} margin={margin}>
      {children} {loading && <Styled.Loader />}
    </Styled.Button>
  );
};

export default Button;

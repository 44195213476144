import { useRef, useState } from "react";
import * as S from "./styles";
import { TagsProps } from "./types";

const Tags = ({ setTagList, tagList }: TagsProps) => {
  const [tagName, setTagName] = useState<string>("");
  const tagInputRef = useRef<any>(null)

  const handleAddTag = () => {
    tagName && setTagList([...tagList, tagName]);
    setTagName("");
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const value = e.target.value;
    setTagName(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    const { key } = e
    const removedTagList = [...tagList]
    removedTagList.pop()
    if (key === 'Backspace' && !tagName) setTagList(removedTagList)
    if (key !== "Enter" && key !== ',') return
    e.preventDefault()
    setTagList([...tagList, tagName]);
    setTagName('')
  }

  const handleClickContainer = () => {
    tagInputRef.current.focus()
  }

  const handleClickRemoveTag = (index: number) => {
    const newTaglist = tagList.filter((_, tagIndex) => tagIndex !== index)

    setTagList(newTaglist)
  }

  return (
    <S.Container onClick={handleClickContainer}>
      {tagList.map((tag, index) => (
        <S.TagWrapper key={`${tag}--${index}`}>
          <S.Tag>{tag}</S.Tag>
          <S.RemoveTag onClick={() => handleClickRemoveTag(index)} type="button">Remover</S.RemoveTag>
        </S.TagWrapper>
      ))}
      <S.TagInput
        onKeyDownCapture={handleKeyDown}
        type="text"
        value={tagName}
        onChangeCapture={handleChangeInput}
        onBlur={handleAddTag}
        ref={tagInputRef}
      />
    </S.Container>
  );
};

export default Tags;

import { useContext, useState } from "react";
import ProductCard from "../../components/ProductCard";
import QuestionList from "../../components/QuestionList";
import Search from "../../components/Search";
import HomeContext from "../../context/home-context";
import api from "../../Services/api";
import * as Styled from "./styles";
import useSliceProduct from "../../hooks/useSliceProduct";

const Home = () => {
  const {
    productList,
    setProductList,
    setWarnList,
    setQuestionList,
    selectedProduct,
  } = useContext<any>(HomeContext);
  const [loading, setLoading] = useState<boolean>(false);
  const storeStorage = localStorage.getItem("store") + "";
  const store = JSON.parse(storeStorage);
  const [sliceProductList, setSliceProductList] = useSliceProduct(productList, 20)

  const handleSearch = (e: any, current: any) => {
    const text = current?.value;
    const url = text
      ? `/search/product/${store.store}/${text}`
      : `/product/list/${store?.store}`;
    setLoading(true);

    api.get(url).then((data) => {
      setProductList(text ? data?.data?.searchResult : data?.data?.productList);
      setLoading(false);
    });
  };

  const handleChangeSearch = (e: any, current: any) => {
    const text = current?.value;

    if (text) return;

    api.get(`/product/list/${store?.store}`).then((data) => {
      setProductList(data?.data?.productList);
    });
  };

  const handleChangeRefresh = () => {
    api
      .get(`/product/list/${store?.store}`)
      .then((data) => {
        const productList = data?.data?.productList;
        const questionList = productList.find(
          (item: any) => item._id === selectedProduct._id
        );
        setProductList(productList);
        setQuestionList(questionList.questions);
      })
      .catch(() => {
        setWarnList("Você não tem permissão para ver os produtos");
        setTimeout(() => setWarnList(""), 7000);
      });
  };

  const handleScrollProducList = (e: any) => {
    if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {  
      setSliceProductList(sliceProductList, 20)
    }  
  }

  return (
    <Styled.Container>
      <Styled.ProductListContainer>
        <Styled.SearchWrapper>
          <Search
            loading={loading}
            onSubmit={handleSearch}
            onChange={handleChangeSearch}
          />
          <Styled.ButtonRefresh onClick={handleChangeRefresh}>
            <Styled.RefreshIcon />
          </Styled.ButtonRefresh>
        </Styled.SearchWrapper>
        <Styled.ProductList onScroll={handleScrollProducList}>
            {sliceProductList?.map((product: any, index: number) => (
              <ProductCard
                key={product?._id}
                id={product?._id}
                productName={product?.productName}
                url={product?.url}
                questions={product?.questions}
                product={product}
                index={index}
              />
            ))}
        </Styled.ProductList>
      </Styled.ProductListContainer>
      <QuestionList />
    </Styled.Container>
  );
};

export default Home;

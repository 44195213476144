import { useState } from "react";
import { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import GlobalContext from "../../context/global-context";
import useStorage from "../../hooks/useStorage";
import api from "../../Services/api";

const Auth = () => {
  const { token } = useParams<any>();

  const [storageToken, setStorageToken] = useStorage("token");
  const [storageUser] = useStorage("user");
  const { setUser } = useContext<any>(GlobalContext);
  const [, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    api
      .get("/auth/validateToken", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        localStorage.setItem(
          "store",
          JSON.stringify({ store: data.store[0]._id })
        );
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(data));
        setStorageToken(token);
        setUser(data);
        setLoading(false);
        history.push("/");
      });
  }, [history, setStorageToken, setUser, storageToken, storageUser, token]);

  return <p>Carregando...</p>;
};

export default Auth;

import { useState } from "react"

const useDate = (defaultDate: Date, diffDays: number = 0) => {
  const newDefaultDate = diffDays ? new Date(defaultDate.setDate(defaultDate.getDate() + diffDays)) : defaultDate
  const [ date, setDate ] = useState<Date | number>(new Date(newDefaultDate))
  
  const changeDate = (newDate: Date | number | any): any => {
    setDate(newDate)
  }

  return [date, changeDate] as const
}

export default useDate
import styled from "styled-components";
import { HiOutlineRefresh } from 'react-icons/hi'
import { ButtonProps } from "../../components/shared/Button/types";

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
  min-height: 89vh;
`;

export const ProductList = styled.ul`
  width: 100%;
  height: 100%;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 15px;
  padding-right: 10px;
`;

export const ProductListContainer = styled.article`
  max-width: 833px;
  padding: 25px;
  margin-right: 21px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  border: 1px solid #d6dbe6;
  flex-direction: column;
  align-items: center;
  width: 50%;
  position: relative;
`;

export const RefreshIcon = styled(HiOutlineRefresh)`
  color: #52BF4D;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: 500ms;

  &:hover{
    transform: rotate(-180deg);
  }
`

export const ButtonRefresh = styled.button<ButtonProps>`
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 20px;
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
import styled from 'styled-components'
import { BsBellFill } from 'react-icons/bs'
import { GoGear } from 'react-icons/go'
import { Link } from 'react-router-dom'

export const Header = styled.header`
  width: 100%;
  height: 80px;
  min-height: 80px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 31px;
`

export const RightBox = styled.div`
  display: flex;
  align-items: center;
`

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  width: fit-content;
  height: fit-content;
  margin-right: 22px;
  cursor: pointer;
  outline: none;
`

export const IconBell = styled(BsBellFill)`
  width: 21px;
  height: 24px;
  color: #5F5D7E;
`

export const IconGear = styled(GoGear)`
  width: 20px;
  height: 20px;
  color: #5F5D7E;
`

export const UserWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UserName = styled.span`
  color: #5F5D7E;
  font-size: 16px;
  font-weight: 700;
  margin-right: 11px;
`

export const UserIcon = styled.div`
  font-size: 20px;
  background-color: #5F5D7E;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

export const NavCenter = styled.nav`
  display: flex;
`

export const NavItem = styled(Link)`
  color: #5F5D7E;
  font-size: 16px;
  text-decoration: none;
  margin: 0 10px;
`

export const BoxConfig = styled.div`
  display: flex;
  position: relative;
`

export const Views = styled.span`
  font-size: 20px;
  font-weight: 700;
`
import { useContext, useEffect, useState } from "react";
import HomeContext from "../../context/home-context";
import * as Styled from "./styles";
import { ProductCardProps } from "./types";

const ProductCard = (props: ProductCardProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const { productName, url, id, questions, product, index } = props;
  const {
    selectedProduct,
    setSelectedProduct,
    setQuestionList,
    setSelectedQuestion,
    productList
  } = useContext<any>(HomeContext);
  const [answeredQuestionAmount, setAnsweredQuestionAmount] = useState<any>(null);
  const [notAnsweredQuestionAmount, setNotAnsweredQuestionAmount] = useState<any>(null);

  useEffect(() => {
    const answeredQuestionAmount = questions?.reduce((accumulator, question): number => {
      if (question.answered) return accumulator + 1;
      return accumulator;
    }, 0);

    const questionQuantity = questions?.length;

    const notAnsweredQuestionAmount =
    (questionQuantity && answeredQuestionAmount !== undefined) ? questionQuantity - answeredQuestionAmount : 0;

    setAnsweredQuestionAmount(answeredQuestionAmount);
    setNotAnsweredQuestionAmount(notAnsweredQuestionAmount);
  }, [questions, selectedProduct]);

  useEffect(() => {
    if (selectedProduct?._id === id) return setSelected(true);
    setSelected(false);
  }, [id, selectedProduct, productList]);

  const handleClickAnswer = () => {
    setSelectedProduct(product);
    setQuestionList(questions);
    setSelectedQuestion("");
  };

  return (
    <Styled.Container selected={selected} onClick={handleClickAnswer} index={index}>
      <Styled.Box>
        <Styled.Title selected={selected}>{productName}</Styled.Title>
        <Styled.ButtonWrapper>
          <Styled.Button margin="0 15px 0 0" href={url} target="_blank">
            Ver produto na loja
          </Styled.Button>
          <Styled.Button onClick={handleClickAnswer}>Responder perguntas</Styled.Button>
        </Styled.ButtonWrapper>
      </Styled.Box>
      <Styled.BoxQuestionQuantity>
        <Styled.AnswerIcon selected={selected} />
        <Styled.QuestionTitle selected={selected}>Respondidas</Styled.QuestionTitle>
        <Styled.Quantity selected={selected} answer={true}>
          {answeredQuestionAmount <= 9 && answeredQuestionAmount !== 0
            ? `0${answeredQuestionAmount}`
            : answeredQuestionAmount}
        </Styled.Quantity>
      </Styled.BoxQuestionQuantity>
      <Styled.BoxQuestionQuantity>
        <Styled.NotAnswerIcon selected={selected} notAnsweredQuestionAmount={notAnsweredQuestionAmount}/>
        <Styled.NotAnswerTitle selected={selected} notAnsweredQuestionAmount={notAnsweredQuestionAmount}>Não respondidas</Styled.NotAnswerTitle>
        <Styled.Quantity selected={selected} notAnsweredQuestionAmount={notAnsweredQuestionAmount}>
          {notAnsweredQuestionAmount <= 9 && notAnsweredQuestionAmount !== 0
            ? `0${notAnsweredQuestionAmount}`
            : notAnsweredQuestionAmount}
        </Styled.Quantity>
      </Styled.BoxQuestionQuantity>
    </Styled.Container>
  );
};

export default ProductCard;

import styled from 'styled-components'

export const Container = styled.ul`
  width: 80%;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 30px;
  z-index: 5;
`

export const WarnCard = styled.li`
  width: 80%;
  padding: 20px;
  border-radius: 8px;
  background-color: #FF80004D;
  border: 1px solid #d6dbe6;
  animation: warnIn 300ms backwards cubic-bezier(.07,.71,.46,1.39);

  @keyframes warnIn {
    from {
      transform: translateY(500%)
    }
    to{
      transform: translateY(0)
    }
  }
`
import { GlobalProvider } from "./context/global-context"
import { StorageProvider } from "./hooks/useStorage"
import Routes from "./routes"

const App = () => {
  return (
    <>
      <StorageProvider>
        <GlobalProvider>
          <Routes />
        </GlobalProvider>
      </StorageProvider>
    </>
  );
};

export default App;

import { useState } from 'react'
import * as S from './styles'
import { DeleteButtonProps } from './types'

const DeleteButton = ({ onDelete }: DeleteButtonProps) => {
  const [ confirm, setConfirm ] = useState<boolean>(false)

  const handleDelete = (e: any) => {
    e.stopPropagation()
    setConfirm(true)
    confirm && onDelete()

    setTimeout(() => {
      setConfirm(false)
    }, 2500)
  }

  return <S.DeleteButton onClick={handleDelete}>
    {confirm ? 'Clique para confirmar' : <S.DeleteIcon />}
  </S.DeleteButton>
}

export default DeleteButton
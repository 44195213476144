import React, { useContext, useState } from "react";
import GlobalContext from "../../context/global-context";
import api from "../../Services/api";
import QuotedProduct from "../QuotedProduct";
import { QuotedProductProps } from "../QuotedProduct/types";
import Search from "../Search";
import * as S from "./styles";

const SearchProduct = () => {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const {
    productToSearch,
    setProductToSearch,
    setSelectedSearchProduct,
  } = useContext<any>(GlobalContext);

  const handleOnChange = () => {
    console.log(productToSearch);
  };

  const handleSubmit = (e: React.KeyboardEvent, text: any) => {
    setLoading(true);
    api.get(`/search/productOnPlatform/${productToSearch}/${text.value}`).then((data) => {
      setProductList(data.data);
      setLoading(false);
    });
  };

  const handleClickClose = () => {
    setProductToSearch("");
  };
  
  const handleSelectProduct = (product: QuotedProductProps) => {
    setSelectedSearchProduct(product)
    setProductToSearch("");
  };

  return (
    <S.Container>
      <S.Close onClick={handleClickClose}>x</S.Close>
      <Search onChange={handleOnChange} onSubmit={handleSubmit} loading={loading} />
      {productList[0] && (
        <S.ProductList>
          {productList?.map((product: any, index) => (
            <QuotedProduct
            key={`${product.platformProductName}--${index}`}
            quotedProduct={product}
            onClick={() => handleSelectProduct(product)}
            />
          ))}
        </S.ProductList>
      )}
    </S.Container>
  );
};

export default SearchProduct;

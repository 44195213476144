const FormatEmailHTML = (HTML) => {
  let newHTML = HTML

   newHTML = newHTML.replace(/{email}/g, 'emailteste@agenciaeplus.com.br');
   newHTML = newHTML.replace(/{question}/g, 'Posso fazer uma pergunta teste de e-mail?')
   newHTML = newHTML.replace(/{answer}/g, 'Sim! pode fazer uma pergunta teste de e-mail')
   newHTML = newHTML.replace(/{productUrl}/g, '#')
   newHTML = newHTML.replace(/{productName}/g, 'Produto teste para e-mail - P')
   newHTML = newHTML.replace(/{store}/g, 'Nome da loja')
  
  return newHTML
}
export default FormatEmailHTML
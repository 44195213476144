import styled, { css } from "styled-components";

interface ButtonProps {
  margin?: string,
  width?: string;
  outlined?: boolean,
  height?: string
  loading?: boolean
}

const outlined = css`
  border: 1px solid #52bf4d;
  background-color: #ffffff;
  color: #52bf4d;
`;

export const Button = styled.button<ButtonProps>`
  width: ${({ width }) => width ?? '100%'};
  height: 40px;
  color: ${({ color }) => color ?? '#ffffff'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  background-color: #52bf4d;
  border: 1px solid #52bf4d;
  border-radius: 5px;
  cursor: pointer;
  margin: ${({ margin }) => margin ?? 0};
  ${(props) => props?.outlined && outlined};
  padding: 11px 25px;
  position: relative;
  color: ${({ loading }) => loading && '#52bf4d'};
  outline: none;
`;

export const Loader = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  border-left: 3px solid #ffffff;
  position: absolute;
  animation: spin 1000ms infinite backwards;

  @keyframes spin {
    from{
      transform: rotate(0)
    }
    to{
      transform: rotate(360deg)
    }
  }
`
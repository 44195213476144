import { useCallback, useEffect, useState } from 'react'
import useStorageContext from './useStorageContext'

const useStorage = (name, initialValue) => {
  const [state, dispatch] = useStorageContext()
  const [loading, setLoading] = useState(true)

  const getItem = useCallback(() => {
    const value = localStorage.getItem(name)
    setLoading(false)
    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }, [name])

  const setItem = useCallback(
    (value) => {
      if (typeof value === 'string') {
        localStorage.setItem(name, value)
      } else {
        localStorage.setItem(name, JSON.stringify(value))
      }
      dispatch({ type: 'set', payload: { name, value } })
    },
    [dispatch, name]
  )

  const removeItem = () => {
    try {
      localStorage.removeItem(name)
      dispatch({ type: 'clear', payload: { name } })
    } catch (error) {}
  }
  const LoadingValue = useCallback(() => {
    const data = getItem()
    !data && !state[name] ? initialValue && setItem(initialValue) : setItem(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getItem, initialValue, name, setItem])

  useEffect(() => {
    LoadingValue()
  }, [LoadingValue])

  return [state[name] ?? initialValue, setItem, removeItem, loading]
}

export default useStorage
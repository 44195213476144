import styled from 'styled-components'

export const Container = styled.div`
  width: 50%;
  height: fit-content;
  max-width: 761px;
  min-height: 490px;
  /* max-height: 490px; */
  /* height: 490px; */
  border: 1px solid #D6DBE6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  background-color: #ffffff;
  position: relative;
`

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 15px;
  max-height: 67vh;
  padding-right: 10px;
  height: 100%;
`

export const BoxQuestionList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 27px;
  height: 100%;
`
import * as S from "./styles";
import { QuotedProductProps } from "./types";

const QuotedProduct = ({ onRemove, quotedProduct, removeButton, ...props}: QuotedProductProps) => {

  const handleRemove = (e:React.MouseEvent) => {
    e.stopPropagation();
    if (onRemove) onRemove(quotedProduct)
  }

  return quotedProduct?.platformProductName ?  (
    <S.Container {...props}>
      <S.BoxImage>
        <S.Image src={quotedProduct?.imageUrl} />
      </S.BoxImage>
      <S.ProductName>{quotedProduct?.platformProductName}</S.ProductName>
      <S.Wrapper>
        {quotedProduct?.formatedListPrice && <S.ListPrice>{quotedProduct?.formatedListPrice}</S.ListPrice>}
        <S.Price>{quotedProduct?.formatedPrice}</S.Price>
      </S.Wrapper>
      {removeButton && <S.RemoveButton onClick={handleRemove}/>}
    </S.Container>
  ) : <></>;
};

export default QuotedProduct;

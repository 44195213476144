import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 48px;
  cursor: text;
`;

export const TagInput = styled.input`
  width: fit-content;
  max-height: 40px;
  border: none;
  outline: none;
  border-radius: 10px;
  display: flex;
`;

export const TagWrapper = styled.div`
  padding: 5px;
  background-color: #52bf4d;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin: 5px;
  cursor: default;
`;

export const Tag = styled.span`
  color: #ffffff;
  white-space: nowrap;
`;

export const RemoveTag = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: #52bf4d;
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 10px;
`;

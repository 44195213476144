import { createContext, ReactNode, useState } from "react";

const GlobalContext = createContext({});

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState({});
  const [productToSearch, setProductToSearch] = useState("");
  const [selectedSearchProduct, setSelectedSearchProduct] = useState({});

  console.log(user)

  return (
    <GlobalContext.Provider
      value={{
        setUser,
        user,
        setProductToSearch,
        productToSearch,
        setSelectedSearchProduct,
        selectedSearchProduct,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;

import React, { useRef } from 'react'
import { Button } from '../shared'
import * as Styled from './styles'
import { SearchProps } from './types'

const Search = (props: SearchProps) => {
  const searchInputRef = useRef(null)
  const { onSubmit, onChange, loading } = props

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(e, searchInputRef?.current)
  }
  
  const handleOnPress = (e: React.KeyboardEvent) => {
    const key = e.key
    if (key === 'Enter') {
      e.preventDefault()
      onSubmit(e, searchInputRef?.current)
    }
  }

  const handleOnChange = (e: React.ChangeEvent) => {
    onChange(e, searchInputRef?.current)
  }

  const handleClickButton = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return(
    <Styled.Container onSubmit={handleSubmit} onKeyPress={handleOnPress}>
      <Styled.BoxInput>
        <Styled.SearchIcon />
        <Styled.Input placeholder="Pesquisar" ref={searchInputRef} onChange={handleOnChange}/>
      </Styled.BoxInput>
      <Button loading={loading} type="button" width='fit-content' margin='0 0 0 10px' onClick={handleClickButton}>Procurar</Button>
    </Styled.Container>
  )
} 

export default Search
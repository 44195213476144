import styled from 'styled-components'

interface LabelStyledProps {
  margin?: string
  for?: string
  fontSize?: string
}

export const Label = styled.label<LabelStyledProps>`
  color: ${({ color }) => color ? color : '#52bf4d'};
  font-size: ${({ fontSize }) => fontSize ? fontSize : '13px'};
  margin: ${({ margin }) => margin ?? 0};
  font-weight: 400;
  white-space: nowrap;
`
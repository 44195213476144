import styled from 'styled-components'
import { ButtonProps, ContainerProps, StatusProps } from './types'

export const Container = styled.li<ContainerProps>`
  width: 100%;
  display: flex;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #D6DBE6;
  padding: 15px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ selected }) => selected ? '#52BF4D' : 'transparent'};
  cursor: pointer;
  transition: 300ms;
  position: relative;

  &:hover{
    border: 1px solid #52BF4D;
  }
`

export const Title = styled.h3<ContainerProps>`
  color: ${({ selected }) => selected ? '#FBFBFB' : '#5F5D7E'};
  font-size: 16px;
  word-break: break-all;
  margin-bottom: 15px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 12px;
`

export const Button = styled.a.attrs((props: ButtonProps) => {})`
  padding: 8px 13px;
  height: 30px;
  background-color: #ECEDF3;
  color: #5F5D7E;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin: ${({ margin }) => margin ?? 0};
  border-radius: 6px;
  text-decoration: none;
  word-break: break-all;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`

export const Status = styled.span<StatusProps>`
  color: ${({ answered }) => answered ? '#52BF4D' : '#EB5757'};
  font-size: 11px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* border: ${({ border }) => border && `1px solid ${border}`}; */
  padding: 5px;
  border-radius: 5px;

  &:before{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ answered }) => answered ? '#52BF4D' : '#EB5757'};
    margin-right: 7px;
    display: ${({ border }) => border && 'none'};
  }
`
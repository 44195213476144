import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../context/global-context";
import HomeContext from "../../context/home-context";
import useStorage from "../../hooks/useStorage";
import api from "../../Services/api";
import QuestionCard from "../QuestionCard";
import { QuestionCardProps } from "../QuestionCard/types";
import Responder from "../Responder";
import Search from "../Search";
import * as Styled from "./styles";

const QuestionList = () => {
  const { questionList, selectedQuestion, setQuestionList, selectedProduct, setProductList, productList } = useContext<any>(HomeContext);
  const { setSelectedSearchProduct } = useContext<any>(GlobalContext);
  const [ loading, setLoading ] = useState<boolean>(false)
  const [store] = useStorage("store");

  const handleSearch = (e: any, current: any) => {
    const text = current?.value
    const url = text ? `/search/question/${store.store}/${selectedProduct?._id}/${text}` : `/question/list/${selectedProduct?._id}`

    setLoading(true)
    
    api.get(url).then((data) => {
      setQuestionList(text ? data?.data?.searchResult : data?.data?.questionList)
      setLoading(false)
    });
  }

  useEffect(() => {
    setSelectedSearchProduct({})
  }, [selectedQuestion, setSelectedSearchProduct])

  const handleChangeSearch = (e: any, current: any) => {
    const text = current?.value

    if (text) return

    api.get(`/question/list/${selectedProduct?._id}`).then((data) => {
      setQuestionList(data?.data?.questionList)
    });
  }

  return (
    <Styled.Container>
      {selectedQuestion?._id ? (
        <Responder></Responder>
      ) : (
        <Styled.BoxQuestionList>
          <Search loading={loading} onSubmit={handleSearch} onChange={handleChangeSearch}/>
          <Styled.QuestionWrapper>
            {questionList && questionList?.map((question: QuestionCardProps, index: number) => (
              <QuestionCard
                key={`${question._id}--${index}`}
                question={question.question}
                answered={question.answered}
                _id={question._id}
                questionObject={question}
                index={index}
                setQuestionList={setQuestionList}
                questionList={questionList}
                productList={productList}
                setProductList={setProductList}
              ></QuestionCard>
            ))}
          </Styled.QuestionWrapper>
        </Styled.BoxQuestionList>
      )}
    </Styled.Container>
  );
};

export default QuestionList;

import styled from "styled-components";

export const Container = styled.section`
  width: 92%;
  max-height: 88vh;
  max-width: 1366px;
  overflow: auto;
  padding: 25px 50px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  border: 1px solid #d6dbe6;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 24px;
  align-items: center;
`;

export const BoxEask = styled.div`
  width: 92%;
  max-width: 1366px;
  padding: 25px 50px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  border: 1px solid #d6dbe6;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 24px;
  align-items: center;
`;

import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import GlobalContext from "../../context/global-context";
import HomeContext from "../../context/home-context";
import useStorage from "../../hooks/useStorage";
import api from "../../Services/api";
import QuotedProduct from "../QuotedProduct";
import { Button, CheckBox, Label } from "../shared";
import Tags from "../Tags";
import * as S from "./styles";
import { BaseEmoji } from "emoji-mart";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

const Responder = () => {
  const {
    setProductList,
    setSelectedQuestion,
    selectedProduct,
    selectedQuestion,
    setQuestionList,
  } = useContext<any>(HomeContext);

  const [answer, setAnswer] = useState<string | any>(
    selectedQuestion?.answer ?? ""
  );
  const [categoryList, setCategoryList] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setProductToSearch, selectedSearchProduct } =
    useContext<any>(GlobalContext);
  const [quotedProduct, setQuotedProduct] = useState<any>(
    selectedQuestion?.quotedProduct
  );
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const emojiRef = useRef(null);
  const [user] = useStorage("user");
  const [store] = useStorage("store");

  const [showOnSite, setShowOnSite] = useState<Boolean>(
    selectedQuestion.showOnWebsite
  );
  const createdAtDay =
    new Date(selectedQuestion.createdAt).getDate() < 10
      ? `0${new Date(selectedQuestion.createdAt).getDate()}`
      : new Date(selectedQuestion.createdAt).getDate();
  const createdAtMonth =
    new Date(selectedQuestion.createdAt).getMonth() + 1 < 10
      ? `0${new Date(selectedQuestion.createdAt).getMonth() + 1}`
      : new Date(selectedQuestion.createdAt).getMonth() + 1;
  const createdAtYear =
    new Date(selectedQuestion.createdAt).getFullYear() < 10
      ? `0${new Date(selectedQuestion.createdAt).getFullYear()}`
      : new Date(selectedQuestion.createdAt).getFullYear();

  useEffect(() => {
    setCategoryList(selectedQuestion?.categories);
  }, [selectedQuestion?.categories, quotedProduct]);

  useEffect(() => {
    selectedSearchProduct?.platformProductName &&
      setQuotedProduct(selectedSearchProduct);
  }, [selectedSearchProduct]);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowEmoji(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(emojiRef);

  const handleSendAnswer = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    const data = await api.put(`/question/answer/${selectedQuestion._id}`, {
      answer,
      showOnWebsite: showOnSite,
      categories: categoryList,
      store: store.store,
      quotedProduct: quotedProduct,
      user: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        id: user?._id,
        email: user?.email,
      },
    });

    setLoading(false);
    setProductList(data.data.productList);
    setQuestionList(data.data.questionList);
    setSelectedQuestion({});
  };

  const handleClickBack = () => {
    setSelectedQuestion("");
  };

  const handleQuoteProduct = () => {
    setProductToSearch(selectedProduct._id);
  };

  const handleChangeAnswer = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(e.target.value);
  };

  const handlePickEmoji = (data: BaseEmoji) => {
    setAnswer(answer + data.native);
  };

  const handleRemoveQuotedProduct = (props: any) => {
    setQuotedProduct({});
  };

  console.log('selectedQuestion', selectedQuestion)

  return (
    <S.Container onSubmitCapture={handleSendAnswer}>
      <S.BackIcon onClick={handleClickBack} />
      <S.Title>{selectedProduct.productName}</S.Title>
      <S.LabelQuestion>Pergunta</S.LabelQuestion>
      <S.QuestionText>{selectedQuestion.question}</S.QuestionText>
      <S.LavelAnswer>Resposta</S.LavelAnswer>
      <S.Wrapper>
        <S.AnswerTextarea
          placeholder="Digite sua resposta aqui"
          value={answer}
          onChange={handleChangeAnswer}
        ></S.AnswerTextarea>
        <S.Emoji onClick={() => setShowEmoji((prev) => !prev)} />
        {showEmoji && (
          <S.PickerBox ref={emojiRef}>
            {" "}
            <Picker native onSelect={handlePickEmoji}></Picker>{" "}
          </S.PickerBox>
        )}
      </S.Wrapper>
      {selectedQuestion?.assignedTo && (
        <S.AnsweredBy>
          Respondido por:{" "}
          {selectedQuestion?.assignedTo
            ? `${selectedQuestion?.assignedTo.firstName} ${selectedQuestion?.assignedTo.lastName}`
            : ""}
        </S.AnsweredBy>
      )}
      {selectedQuestion.userEmail && <S.QuestionText>E-mail: <b>{`${selectedQuestion.userEmail}`}</b></S.QuestionText>}
      {selectedQuestion.userEmail && <S.Warn><b>Aviso!</b> Nunca envie o e-mail do usuário na resposta.</S.Warn>}
      <S.Wrapper>
        <Button margin="0 0 10px 0" type="button" onClick={handleQuoteProduct}>
          Citar produto
        </Button>
        <QuotedProduct
          onRemove={handleRemoveQuotedProduct}
          onClick={handleQuoteProduct}
          quotedProduct={quotedProduct}
          removeButton
        />
      </S.Wrapper>
      <S.Wrapper>
        <Label margin="0 0 10px 0">Categorias:</Label>
        <Tags setTagList={setCategoryList} tagList={categoryList} />
      </S.Wrapper>
      <S.ButtonAnswerWrapper>
        <CheckBox
          stateToggle={setShowOnSite}
          selected={!showOnSite}
          label="Não mostrar no site"
        ></CheckBox>
        <Button
          loading={loading}
          type="submit"
          width="unset"
          margin="0 0 0 27px"
        >
          {selectedQuestion?.answer ? "Retificar Resposta" : "Enviar Resposta"}
        </Button>
      </S.ButtonAnswerWrapper>
      <S.Date>{`Data da pergunta: ${createdAtDay} / ${createdAtMonth} / ${createdAtYear}`}</S.Date>
    </S.Container>
  );
};

export default Responder;

import styled from 'styled-components'
import { BoxCheckboxProps } from './types'

export const Box = styled.div<BoxCheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin ?? margin}
`

export const CheckBox = styled.div`
  width: 25px;
  height: 25px;
  border: 1px solid #52BF4D;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Label = styled.label`
  font-size: 16px;
  color: #5F5D7E;
  margin-left: 7px;
`
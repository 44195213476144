/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Chart from "../../components/Chart";
import DropDownCalendar from "../../components/DropDownCalendar";
import useDate from "../../hooks/useDate";
import api from "../../Services/api";
import * as S from "./styles";
import { ResponseChartProps } from "./types";

const Analyst = () => {
  const [chartInfo, setChartInfo] = useState<ResponseChartProps[]>([]);
  const store = JSON.parse(localStorage.getItem("store") + "");
  const [inicialDate, setInicialDate] = useDate(new Date(), -30);
  const [finalDate, setFinalDate] = useDate(new Date());
  const [loading, setLoading] = useState(false);
  const active = false

  useEffect(() => {
    if (!active) return;
    setLoading(true);
    api
      .get(
        `/analyst/allChart/${store.store}?inicialDate=${inicialDate}&finalDate=${finalDate}`
      )
      .then(({ data }) => {
        setChartInfo(data);
        setLoading(false);
      });
  }, [inicialDate, finalDate, store.store]);

  const handleSelect = (rangeDate: any) => {
    if (!rangeDate[1]) return;
    setInicialDate(new Date(rangeDate[0]));
    setFinalDate(new Date(rangeDate[1]));
  };

  return (active ? 
    <S.Container>
      <DropDownCalendar
        defaultDate={[inicialDate, finalDate]}
        selectRange
        returnValue="range"
        onChange={handleSelect}
      />
      {loading ? (
        <p>
          Estamos buscando os dados pra você, isso pode demorar alguns segundos.
          Já estamos trabalhando para melhoras...
        </p>
      ) : (
        chartInfo.map((item, index) => (
          <Chart
            title={item.title}
            key={`${index}--chart`}
            options={{ labels: item.label }}
            series={[{ data: item.data }]}
            type="bar"
            width="1000"
            chartType={item.type}
            limit={item.title === "Classificação produtos mais vistos" ? 20 : 0}
          />
        ))
      )}
    </S.Container>
    : <h1 style={{margin: '100px 0 0 0'}}>O analista está em manutenção. Em breve ele voltará. Obrigado.</h1>
  );
};

export default Analyst;
